.contact-form {
	position: relative;
	background-color: rgba($color: $heading-color, $alpha: 0.02);
	padding: 40px 30px;
	border-radius: 5px;
	margin-top: 40px;

	input,
	textarea {
		width: 100%;
		height: 60px;
		padding: 0px 30px;
		background-color: #fff;
		border: 0px;
		border-radius: 3px;
		color: $heading-color;
		font-size: 18px;
		transition: all 0.3s ease-in-out;
		margin-bottom: 20px;
		box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.04);
		border-radius: 3px;

		&:focus {
			&::placeholder {
				opacity: 0;
			}
		}

		@media (max-width: 767px) {
			height: 50px;
			font-size: 16px;
			padding-left: 15px;
		}

		&::placeholder {
			color: rgba($color: $heading-color, $alpha: 0.6);
		}
	}

	textarea {
		height: 220px;
		padding-top: 30px;

		@media (max-width: 767px) {
			height: 120px;
		}
	}

	button {
		width: 100%;
		margin-top: 10px;
	}
}

.google-map {
	margin-top: 40px;
	height: 100%;

	iframe {
		height: 650px;
		width: 100%;

		@media (max-width: 1200px) {
			height: 500px;
		}

		@media (max-width: 767px) {
			height: 300px;
		}

		@media (max-width: 58px) {
			height: 220px;
		}
	}
}

.single-contact-box {
	box-shadow: 0px 4px 80px rgba(8, 15, 30, 0.06);
	border-radius: 5px;
	background-color: #fff;
	padding: 40px;
	margin-bottom: 30px;
	transition: all 0.3s ease-in-out;

	&:hover {
		transform: translateY(-5px);
	}

	&.box2 {
		.icon {
			background: #32c072;
		}
	}

	&.box3 {
		.icon {
			background: #ffd44b;
		}
	}

	.icon {
		width: 60px;
		height: 60px;
		line-height: 60px;
		color: #fff;
		background-color: #ef2469;
		margin: 0 auto;
		margin-bottom: 25px;
		border-radius: 50%;
	}

	.contact-info {
		span {
			color: #617686;
			display: block;
			margin-top: 10px;
			font-weight: 500;
			font-size: 20px;
			line-height: 1;
		}
	}
}
