
.coverbg {
    @include coverbg;
}

.mx-auto {
	margin: 0 auto;
}

.align-center {
	@include align-center;

	&:before, &:after {
    	content: none;
    }
}

.align-center-h {
	@include align-center('horizontal');

	&:before, &:after {
    	content: none;
    }
}

.align-center-v {
	height: 100%;
	@include align-center('vertical');

	&:before, &:after {
    	content: none;
    }
}

.align-center-v-b {
	height: 100%;
	@include align-center('bottom');

	&:before, &:after {
    	content: none;
    }
}

.justify-content-center {
	-webkit-box-pack: center!important;
	-webkit-justify-content: center!important;
	-ms-flex-pack: center!important;
	justify-content: center!important;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    &:before, &:after {
    	content: none;
    }
}

.eq-height {
	display: -webkit-box;
	display: -moz-flexbox;
	display: -ms-flexbox;
	display: -o-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;

	&:before, &:after {
    	content: none;
    }
}