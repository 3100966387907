// Section Padding - Change with your need.
.section-padding {
  padding: 10px 0px;

  @media (max-width: 991px) {
    padding: 100px 0px;
  }

  @media (max-width: 767px) {
    padding: 80px 0px;
  }
}

.block-contents {
  position: relative;

  .section-title {
    margin-bottom: 50px;

    @media (max-width: 991px) {
      margin-bottom: 30px;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }

    h2 {
      margin-top: -14px;
      font-size: 60px;
      line-height: 112%;

      @media (max-width: 1200px) {
        font-size: 50px;
      }

      @media (max-width: 991px) {
        font-size: 40px;
      }

      @media (max-width: 767px) {
        font-size: 36px;
        margin-top: -6px;
      }

      @media (max-width: 485px) {
        font-size: 30px;
      }
    }
  }

  p {
    margin-bottom: 15px;
  }
}

.legal-contents {
  position: relative;

  .section-title {
    margin-bottom: 50px;

    @media (max-width: 991px) {
      margin-bottom: 30px;
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }

    h2 {
      margin-top: -14px;
      font-size: 60px;
      line-height: 112%;

      @media (max-width: 1200px) {
        font-size: 50px;
      }

      @media (max-width: 991px) {
        font-size: 40px;
      }

      @media (max-width: 767px) {
        font-size: 36px;
        margin-top: -6px;
      }

      @media (max-width: 485px) {
        font-size: 30px;
      }
    }
  }

  p {
    margin-bottom: 15px;
    font-size: 14px;
    color: rgba($color: #000, $alpha: 0.7);
  }
}

.section-bg {
  background-color: $section-bg;
}

.section-bg-2 {
  background-color: rgba($color: #080f1e, $alpha: 0.02);
}

.theme-bg {
  background: $theme-color;

  h1,
  h2,
  h4,
  h5,
  h6,
  a {
    color: #fff;
  }

  p,
  span {
    color: rgba($color: #fff, $alpha: 0.7);
  }
}

.section-bg-3 {
  background-color: rgba($color: #080f1e, $alpha: 0.02);
}

.section-black {
  background-color: $section-bg-black;
}
