/* ------------------------------------------------------------------
   Template Default Button, Input Styles - Value Change with Your's Need
 -------------------------------------------------------------------- */
.btnd {
  line-height: 1;
  display: inline-block;
  @include transition(0.3s);
  position: relative;
}

.theme-btn {
  color: $black;
  @extend .btnd;
  padding: 17px 35px;
  font-size: 18px;
  font-weight: 600;
  color: $white;
  background-color: $theme-color;
  border: 2px solid $theme-color;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;

  @media (max-width: 580px) {
    padding: 14px 34px;
    font-size: 15px;
  }

  @media (max-width: 480px) {
    padding: 13px 30px;
    font-size: 14px;
  }

  &.no-fil {
    background-color: transparent;
    border-color: rgba($color: #fff, $alpha: 0.2);
    margin-left: 15px;

    @media (max-width: 500px) {
      margin-left: 0;
    }

    &:hover {
      background-color: $theme-color;
      border: 2px solid $theme-color;
    }
  }

  &.black {
    background-color: $black;
    border-color: $black;
    color: #fff;

    &:hover {
      background-color: $theme-color;
      border: 2px solid $theme-color;
    }
  }

  &.minimal-btn {
    color: #727475;
    border: 2px solid #ebebeb;
    background: transparent;

    &:hover {
      background-color: $theme-color;
      border-color: $theme-color;
    }
  }

  &.second-color {
    background-color: $second-color;
    color: #fff;
    border: 2px solid $second-color;

    &:hover {
      background-color: $theme-color;
      border-color: $theme-color;
    }
  }

  &.red-color {
    background-color: #f54748;
    color: #fff;
    border: 2px solid #f54748;

    &.minimal-btn {
      color: #f54748 !important;

      &:hover {
        color: #fff !important;
      }
    }

    &:hover {
      background-color: $theme-color !important;
      border-color: $theme-color !important;
    }
  }

  &:hover {
    background-color: $second-color;
    border: 2px solid $second-color;
    color: $white;
  }
}

.video-btn {
  @extend .btnd;

  font-size: 16px;
  font-weight: 700;
  color: #726ceb;

  i {
    margin-right: 10px;
  }
}

.video-play-btn {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: $heading-color;
  color: #f54748 !important;
  line-height: 100px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  animation: playVideo infinite 3s linear;
  display: inline-block;
  margin: 0px auto;
  font-size: 20px;
  box-shadow: 0px 16px 32px 0px rgba(51, 51, 51, 0.04);
  cursor: pointer;

  @media (max-width: 767px) {
    width: 80px;
    height: 80px;
    line-height: 80px !important;
  }

  &:hover {
    color: $second-color;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  .play-text {
    font-weight: 700;
  }
}
