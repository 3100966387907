/* ----------------------------------
    Template Helps Classes - Styles
 ------------------------------------ */
.text-white {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	span,
	a {
		color: #fff !important;
	}
}

.mlm-30 {
	margin-left: -30px;
}

/* padding left */
.pl-5 {
	padding-left: 5px;
}

.pl-10 {
	padding-left: 10px;
}

.pl-15 {
	padding-left: 15px;
}

.pl-20 {
	padding-left: 20px;
}

.pl-25 {
	padding-left: 25px;
}

.pl-30 {
	padding-left: 30px;
}

.pl-35 {
	padding-left: 35px;
}

.pl-40 {
	padding-left: 40px;
}

.pl-45 {
	padding-left: 45px;
}

.pl-50 {
	padding-left: 50px;
}

.pl-60 {
	padding-left: 60px;
}

.pl-65 {
	padding-left: 65px;
}

.pl-70 {
	padding-left: 70px;
}

.pl-75 {
	padding-left: 75px;
}

.pl-80 {
	padding-left: 80px;
}

.pl-90 {
	padding-left: 90px;
}

.pl-100 {
	padding-left: 100px;
}

.pl-110 {
	padding-left: 110px;
}

.pl-115 {
	padding-left: 115px;
}

.pl-120 {
	padding-left: 120px;
}

.pl-125 {
	padding-left: 125px;
}

.pl-130 {
	padding-left: 130px;
}

.pl-140 {
	padding-left: 140px;
}

.pl-150 {
	padding-left: 150px;
}

/* padding right */
.pr-5 {
	padding-right: 5px;
}
.pr-5i {
	padding-right: 5px !important;

	@media (max-width: 767px) {
		padding-right: 15px !important;
	}
}

.pr-10 {
	padding-right: 10px;
}

.pr-15 {
	padding-right: 15px;
}

.pr-20 {
	padding-right: 20px;
}

.pr-25 {
	padding-right: 25px;
}

.pr-30 {
	padding-right: 30px;
}

.pr-35 {
	padding-right: 35px;
}

.pr-40 {
	padding-right: 40px;
}

.pr-45 {
	padding-right: 45px;
}

.pr-50 {
	padding-right: 50px;
}

.pr-60 {
	padding-right: 60px;
}

.pr-70 {
	padding-right: 70px;
}

.pr-75 {
	padding-right: 75px;
}

.pr-80 {
	padding-right: 80px;
}

.pr-90 {
	padding-right: 90px;
}

.pr-100 {
	padding-right: 100px;
}

.pr-110 {
	padding-right: 110px;
}

.pr-115 {
	padding-right: 115px;
}

.pr-120 {
	padding-right: 120px;
}

.pr-125 {
	padding-right: 125px;
}

.pr-130 {
	padding-right: 130px;
}

.pr-140 {
	padding-right: 140px;
}

.pr-150 {
	padding-right: 150px;
}

/* padding top */
.pt-5 {
	padding-top: 5px;
}

.pt-10 {
	padding-top: 10px;
}

.pt-15 {
	padding-top: 15px;
}

.pt-20 {
	padding-top: 20px;
}

.pt-25 {
	padding-top: 25px;
}

.pt-30 {
	padding-top: 30px;
}

.pt-35 {
	padding-top: 35px;
}

.pt-40 {
	padding-top: 40px;
}

.pt-45 {
	padding-top: 45px;
}

.pt-50 {
	padding-top: 50px;
}

.pt-60 {
	padding-top: 60px;
}

.pt-65 {
	padding-top: 65px;
}

.pt-70 {
	padding-top: 70px;
}

.pt-75 {
	padding-top: 75px;
}

.pt-80 {
	padding-top: 80px;
}

.pt-90 {
	padding-top: 90px;
}

.pt-100 {
	padding-top: 100px;
}

.pt-110 {
	padding-top: 110px;
}

.pt-115 {
	padding-top: 115px;
}

.pt-120 {
	padding-top: 120px;
}

.pt-125 {
	padding-top: 125px;
}

.pt-130 {
	padding-top: 130px;
}

.pt-140 {
	padding-top: 140px;
}

.pt-150 {
	padding-top: 150px;
}

/* padding bottom */
.pb-5 {
	padding-bottom: 5px;
}

.pb-10 {
	padding-bottom: 10px;
}

.pb-15 {
	padding-bottom: 15px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-25 {
	padding-bottom: 25px;
}

.pb-30 {
	padding-bottom: 30px;
}

.pb-35 {
	padding-bottom: 35px;
}

.pb-40 {
	padding-bottom: 40px;
}

.pb-45 {
	padding-bottom: 45px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-60 {
	padding-bottom: 60px;
}

.pb-65 {
	padding-bottom: 65px;
}

.pb-70 {
	padding-bottom: 70px;
}

.pb-75 {
	padding-bottom: 75px;
}

.pb-80 {
	padding-bottom: 80px;
}

.pb-90 {
	padding-bottom: 90px;
}

.pb-100 {
	padding-bottom: 100px;
}

.pb-110 {
	padding-bottom: 110px;
}

.pb-115 {
	padding-bottom: 115px;
}

.pb-120 {
	padding-bottom: 120px;
}

.pb-125 {
	padding-bottom: 125px;
}

.pb-130 {
	padding-bottom: 130px;
}

.pb-140 {
	padding-bottom: 140px;
}

.pb-150 {
	padding-bottom: 150px;
}

/* margin left */
.ml-5 {
	margin-left: 5px;
}

.ml-10 {
	margin-left: 10px;
}

.ml-15 {
	margin-left: 15px;
}

.ml-20 {
	margin-left: 20px;
}

.ml-25 {
	margin-left: 25px;
}

.ml-30 {
	margin-left: 30px;
}

.ml-35 {
	margin-left: 35px;
}

.ml-40 {
	margin-left: 40px;
}

.ml-45 {
	margin-left: 45px;
}

.ml-50 {
	margin-left: 50px;
}

.ml-60 {
	margin-left: 60px;
}

.ml-70 {
	margin-left: 70px;
}

.ml-75 {
	margin-left: 75px;
}

.ml-80 {
	margin-left: 80px;
}

.ml-90 {
	margin-left: 90px;
}

.ml-100 {
	margin-left: 100px;
}

.ml-110 {
	margin-left: 110px;
}

.ml-115 {
	margin-left: 115px;
}

.ml-120 {
	margin-left: 120px;
}

.ml-125 {
	margin-left: 125px;
}

.ml-130 {
	margin-left: 130px;
}

.ml-140 {
	margin-left: 140px;
}

.ml-150 {
	margin-left: 150px;
}

/* margin right */
.mr-5 {
	margin-right: 5px;
}

.mr-10 {
	margin-right: 10px;
}

.mr-15 {
	margin-right: 15px;
}

.mr-20 {
	margin-right: 20px;
}

.mr-25 {
	margin-right: 25px;
}

.mr-30 {
	margin-right: 30px;
}

.mr-35 {
	margin-right: 35px;
}

.mr-40 {
	margin-right: 40px;
}

.mr-45 {
	margin-right: 45px;
}

.mr-50 {
	margin-right: 50px;
}

.mr-60 {
	margin-right: 60px;
}

.mr-70 {
	margin-right: 70px;
}

.mr-75 {
	margin-right: 75px;
}

.mr-80 {
	margin-right: 80px;
}

.mr-90 {
	margin-right: 90px;
}

.mr-100 {
	margin-right: 100px;
}

.mr-110 {
	margin-right: 110px;
}

.mr-115 {
	margin-right: 115px;
}

.mr-120 {
	margin-right: 120px;
}

.mr-125 {
	margin-right: 125px;
}

.mr-130 {
	margin-right: 130px;
}

.mr-140 {
	margin-right: 140px;
}

.mr-150 {
	margin-right: 150px;
}

.mtm-5 {
	margin-top: -5px;
}

.mtm-10 {
	margin-top: -10px;
}

.mtm-20 {
	margin-top: -20px;
}

.mtm-30 {
	margin-top: -30px;
}

.mtm-40 {
	margin-top: -40px;
}

.mtm-50 {
	margin-top: -50px;
}
.mtm-120 {
	margin-top: -120px;
}

/* margin top */
.mt-5 {
	margin-top: 5px;
}

.mt-10 {
	margin-top: 10px;
}

.mt-15 {
	margin-top: 15px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-25 {
	margin-top: 25px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-35 {
	margin-top: 35px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-45 {
	margin-top: 45px;
}

.mt-50 {
	margin-top: 50px;
}

.mt-60 {
	margin-top: 60px;
}

.mt-70 {
	margin-top: 70px;
}

.mt-75 {
	margin-top: 75px;
}

.mt-80 {
	margin-top: 80px;
}

.mt-90 {
	margin-top: 90px;
}

.mt-100 {
	margin-top: 100px;
}

.mt-110 {
	margin-top: 110px;
}

.mt-115 {
	margin-top: 115px;
}

.mt-120 {
	margin-top: 120px;
}

.mt-125 {
	margin-top: 125px;
}

.mt-130 {
	margin-top: 130px;
}

.mt-140 {
	margin-top: 140px;
}

.mt-150 {
	margin-top: 150px;
}

/* margin bottom */
.mb-5 {
	margin-bottom: 5px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-25 {
	margin-bottom: 25px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-35 {
	margin-bottom: 35px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-45 {
	margin-bottom: 45px;
}

.mb-50 {
	margin-bottom: 50px;
}

.mb-55 {
	margin-bottom: 55px;
}

.mb-60 {
	margin-bottom: 60px;
}

.mb-70 {
	margin-bottom: 70px;
}

.mb-75 {
	margin-bottom: 75px;
}

.mb-80 {
	margin-bottom: 80px;
}

.mb-90 {
	margin-bottom: 90px;
}

.mb-100 {
	margin-bottom: 100px;
}

.mb-110 {
	margin-bottom: 110px;
}

.mb-115 {
	margin-bottom: 115px;
}

.mb-120 {
	margin-bottom: 120px;
}

.mb-125 {
	margin-bottom: 125px;
}

.mb-130 {
	margin-bottom: 130px;
}

.mb-140 {
	margin-bottom: 140px;
}

.mb-150 {
	margin-bottom: 150px;
}

.mmb10 {
	margin-bottom: -10px;
}

/* background */
.bg-cover {
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	background-position: center;
}

.bg-center {
	background-position: center;
}

.bg-contain {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	position: relative;
}

.bg-right {
	background-repeat: no-repeat;
	background-position: right;
}

.bg-left {
	background-repeat: no-repeat;
	background-position: left;
}

.bg-bottom {
	background-repeat: no-repeat;
	background-position: bottom;
}

.bg-top {
	background-repeat: no-repeat;
	background-position: top;
}

.bg-100 {
	-webkit-background-size: 100% 100%;
	background-size: 100% 100%;
}

.mmb-100 {
	margin-bottom: -100px;
}
.mmb-80 {
	margin-bottom: -80px;
}

.lr-9 {
	margin-left: -9px;
	margin-right: -9px;

	[class*="col"] {
		padding-left: 9px;
		padding-right: 9px;
	}
}

.checked-list {
	margin-top: 40px;

	@media (max-width: 767px) {
		margin-top: 20px;
	}

	&.bg-checked {
		&.color-set {
			li {
				&::before {
					color: #32c072;
				}
				&:last-child {
					&::before {
						color: #ffd44b;
					}
				}
			}
		}

		li {
			background-color: #fff;
			filter: drop-shadow(0px 4px 80px rgba(8, 15, 30, 0.06));
			padding: 15px 60px !important;
			margin-top: 20px;
			padding-right: 30px;
			display: inline-block;

			@media (max-width: 767px) {
				padding-right: 15px !important;
			}

			&::before {
				top: 25%;
				left: 30px;

				@media (max-width: 767px) {
					left: 20px;
				}
			}

			&:last-child {
				&::before {
					color: #ef2469;
				}
			}
		}
	}

	&.color {
		li {
			&::before {
				color: $theme-color;
			}
		}
	}

	&.right {
		li {
			padding-left: 0px;
			padding-right: 30px;

			&::before {
				right: 0;
				left: auto;
			}
		}
	}

	li {
		margin-top: 10px;
		position: relative;
		font-size: 20px;
		padding-left: 35px;
		font-weight: 500;

		@media (max-width: 767px) {
			font-size: 18px;
		}

		&.no,
		&.not {
			&::before {
				content: "\f00d";
				color: $second-color;
			}
		}

		&::before {
			position: absolute;
			left: 0;
			top: 0;
			font-family: "xmoze";
			content: "";
			color: $theme-color;
		}
	}
}

.bg-overlay {
	position: relative;
	z-index: 1;

	&::before {
		position: absolute;
		left: 0;
		content: "";
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0.2;
		background-color: $theme-color;
		z-index: -1;
	}
}

.fix {
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.shape {
	position: absolute;

	@media (max-width: 1191px) {
		display: none;
		opacity: 0;
	}
}

.navbar-brand {
	padding: 0;
	margin-right: 0;
}

#scrollUp {
	position: fixed;
	background: #00baa3;
	border-radius: 50%;
	bottom: 0px;
	color: #fff;
	font-size: 18px;
	height: 40px;
	line-height: 44px;
	right: 20px;
	text-align: center;
	width: 40px;
	box-shadow: 0px 5px 15px 0px rgba(1, 29, 80, 0.1);
	transition: all 0.5s ease-in-out;

	z-index: 990;
	bottom: 20px;

	i {
		color: #fff;
	}

	&:hover {
		background-color: #f86048;
		color: #fff;
	}
}

@media only screen and (max-width: 991px) {
	#scrollUp {
		font-size: 15px;
		height: 30px;
		line-height: 32px;
		right: 30px;
		width: 30px;
	}
}

button {
	border: none;
}
section {
	position: relative;
	z-index: 1;

	&.pt-235 {
		padding-top: 225px;
	}
}

@keyframes sticky {
	0% {
		opacity: 0;
		margin-top: -120px;
	}
	50% {
		opacity: 0;
		margin-top: -60px;
	}
	100% {
		opacity: 1;
		margin-top: 0;
	}
}
.equal {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
}
.equal > [class*="col-"] {
	display: flex;
	flex-direction: column;
}

.d-none-mobile {
	@media (max-width: 414px) {
		display: none !important;
	}
}

.overlay {
	transition: all 0.2s ease-in-out;
	width: 40%;
	opacity: 0;
	visibility: hidden;

	&.active {
		position: fixed;
		right: 0;
		top: 0;
		height: 100%;
		width: 100%;
		content: "";
		background-color: #000;
		z-index: 99;
		opacity: 0.5;
		visibility: visible;
	}
}

// Background Image Animations
@keyframes left-to-right {
	0% {
		background-position: left center;
	}
	100% {
		background-position: right center;
	}
}

@keyframes right-to-left {
	0% {
		background-position: right center;
	}
	100% {
		background-position: left center;
	}
}

@keyframes left-to-center {
	0% {
		background-position: left center;
	}
	100% {
		background-position: center center;
	}
}

@keyframes right-to-center {
	0% {
		background-position: right center;
	}
	100% {
		background-position: center center;
	}
}

@keyframes top-to-bottom {
	0% {
		background-position: center top;
	}
	100% {
		background-position: center bottom;
	}
}

@keyframes top-to-center {
	0% {
		background-position: center top;
	}
	100% {
		background-position: center center;
	}
}

@keyframes bottom-to-top {
	0% {
		background-position: center bottom;
	}
	100% {
		background-position: center top;
	}
}

@keyframes bottom-to-center {
	0% {
		background-position: center bottom;
	}
	100% {
		background-position: center center;
	}
}

@keyframes top-left-to-bottom-right {
	0% {
		background-position: left top;
	}
	100% {
		background-position: right bottom;
	}
}

@keyframes top-left-to-center {
	0% {
		background-position: left top;
	}
	100% {
		background-position: center center;
	}
}

@keyframes top-right-to-bottom-left {
	0% {
		background-position: right top;
	}
	100% {
		background-position: left bottom;
	}
}

@keyframes top-right-to-center {
	0% {
		background-position: right top;
	}
	100% {
		background-position: center center;
	}
}

@keyframes bottom-left-to-top-right {
	0% {
		background-position: left bottom;
	}
	100% {
		background-position: right top;
	}
}

@keyframes bottom-left-to-center {
	0% {
		background-position: left bottom;
	}
	100% {
		background-position: center center;
	}
}

@keyframes bottom-right-to-top-left {
	0% {
		background-position: right bottom;
	}
	100% {
		background-position: left top;
	}
}

@keyframes bottom-right-to-center {
	0% {
		background-position: right bottom;
	}
	100% {
		background-position: center center;
	}
}

@keyframes zoomIn {
	0% {
		background-position: right center;
	}
	100% {
		background-position: left center;
	}
}

$animation-list: (
	left-to-right left center,
	right-to-left right center,
	left-to-center left center,
	right-to-center right center,
	top-to-bottom center top,
	top-to-center center top,
	bottom-to-top center bottom,
	bottom-to-center center bottom,
	top-left-to-bottom-right left top,
	top-left-to-center left top,
	top-right-to-bottom-left right top,
	top-right-to-center right top,
	bottom-left-to-top-right left bottom,
	bottom-left-to-center left bottom,
	bottom-right-to-top-left right bottom,
	bottom-right-to-center right bottom
);

@each $animation-name, $animation-start-x, $animation-start-y in $animation-list {
	.animation {
		&_#{$animation-name} {
			background-position: #{$animation-start-x} #{$animation-start-y};

			.active & {
				animation: #{$animation-name} 5s ease 0s 1 normal forwards running;
			}
		}
	}
}
/* Alignments
--------------------------------------------- */
.alignleft {
	/*rtl:ignore*/
	float: left;

	/*rtl:ignore*/
	margin-right: 1.5em;
	margin-bottom: 1.5em;
}

.alignright {
	/*rtl:ignore*/
	float: right;

	/*rtl:ignore*/
	margin-left: 1.5em;
	margin-bottom: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1.5em;
}

nav {
	.breadcrumb {
		list-style: none;

		li {
			list-style: none;
		}
	}
}

.no-padding {
	.col-12 {
		padding: 0;
	}
}

.mask-outline {
	background-repeat: no-repeat;
	background-position: center;
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;

	h1 {
		font-size: 200px;
		margin-bottom: 0;
		line-height: 1;

		@media (max-width: 991px) {
			font-size: 150px;
		}

		@media (max-width: 576px) {
			font-size: 100px;
		}
	}

	h2 {
		font-size: 115px;
		margin-bottom: 0;
		line-height: 1;
	}

	h3 {
		font-size: 80px;
		margin-bottom: 0;
		line-height: 1;
	}
}

.fix {
	overflow: hidden;
}
