.single-crypto-currency-card {
	padding: 30px;
	background-color: #fff;
	border-radius: 3px;
	margin-top: 30px;
	transition: all 0.3s ease-in-out;

	&:hover {
		transform: translateY(-5px);
	}

	.currency-header {
		overflow: hidden;
		clear: both;
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba($color: #617686, $alpha: 0.1);
		padding-bottom: 25px;
		margin-bottom: 20px;

		.icon {
			width: 75px;
			height: 75px;
			line-height: 75px;
			text-align: center;
			background-color: #ff784a;
			color: #fff;
			border-radius: 50%;
			float: left;
			margin-right: 15px;
			overflow: hidden;
			font-size: 32px;

			&.icon2 {
				background-color: #02021e;
			}

			&.icon3 {
				background-color: #4243dc;
			}

			&.icon4 {
				background-color: #617686;
			}

			&.icon5 {
				background-color: #f3ba2f;
			}

			&.icon6 {
				background-color: #00a478;
			}
		}

		.currency-name {
			overflow: hidden;

			h6 {
				margin-bottom: 2px;
			}

			span {
				font-size: 18px;
				line-height: 1;
			}
		}
	}

	.currency-info {
		p {
			font-size: 18px;
		}
	}

	.currency-rate-buy-btn {
		margin-top: 35px;

		.currency-rate {
			h5 {
				margin-bottom: 0;
				color: #02021e;
				line-height: 1;
			}
		}

		.currency-buy-now {
			a {
				display: inline-block;
				padding: 11px 26px;
				font-size: 16px;
				line-height: 1;
				color: $theme-color;
				border: 2px solid $theme-color;
				border-radius: 3px;
				text-transform: capitalize;
				font-weight: 600;
				transition: all 0.4s ease-in-out;

				&:hover {
					background-color: $theme-color;
					color: #fff;
				}
			}
		}
	}
}

.nice-arrow-icon {
	position: absolute;
	top: 20px;

	img {
		max-width: 92%;
	}
}

.pricing-toggle {
	text-align: center;
	position: relative;

	.nav {
		display: inline-block;
		background: #fff !important;
		border-radius: 50px;
		padding: 3px;
		filter: drop-shadow(0px 4px 80px rgba(8, 15, 30, 0.06));

		.nav-item,
		.react-tabs__tab {
			display: inline-block;

			.nav-link {
				border: 0px;
				background-color: #fff;
				padding: 10px 34px;
				display: inline-block;
				border-radius: 30px;
				color: $second-color;
				font-size: 16px;
				font-weight: 600;
				transition: all 0.3s ease-in-out;
			}
		}
		.react-tabs__tab--selected {
			.nav-link {
				background-color: #1a1aff;
				color: #fff;
			}
		}
	}
}

.single-pricing-package {
	background-color: #fff;
	padding: 40px 45px;
	box-shadow: 0px 4px 80px rgba(8, 15, 30, 0.06);
	border-radius: 10px;
	position: relative;
	margin-top: 30px;
	overflow: hidden;

	@media (max-width: 767px) {
		padding: 30px;
	}

	.package-bage {
		position: absolute;
		right: 20px;
		top: -1px;
		content: "";
		z-index: 1;
		width: 60px;
		height: 120px;
		background: #ffd44b;
		-webkit-clip-path: polygon(100% 0, 100% 100%, 50% 77%, 0 100%, 0 0);
		clip-path: polygon(100% 0, 100% 100%, 50% 77%, 0 100%, 0 0);

		span {
			transform: rotate(270deg);
			font-size: 16px;
			font-weight: 600;
			color: $second-color !important;
			position: absolute;
			top: 30%;
		}
	}

	&.style-2 {
		&.package2 {
			.pricing-name {
				h4 {
					color: #32c072 !important;
				}
			}
		}

		&.package3 {
			.pricing-name {
				h4 {
					color: #4243dc !important;
				}
			}
		}

		.pricing-head {
			border-bottom: 1px solid rgba($color: $second-color, $alpha: 0.1) !important;

			.pricing-name {
				h4 {
					color: #ef2469;
				}
			}

			.pricing-vale {
				align-items: baseline;

				h2 {
					color: $second-color;
				}
			}
		}

		ul {
			li {
				color: $second-color;

				&::before {
					color: $second-color !important;
				}
			}
		}

		.theme-btn {
			color: #1a1aff !important;
			border-color: #1a1aff;

			&:hover {
				color: #fff !important;
				border-color: #1a1aff !important;
				background-color: #1a1aff !important;
			}
		}

		&.active {
			background-color: #fff;

			.pricing-head {
				border-bottom: 1px solid rgba($color: $second-color, $alpha: 0.1) !important;
			}

			p {
				color: $second-color !important;
			}

			h2 {
				span {
					color: $second-color !important;
				}
			}

			.theme-btn {
				background-color: #1a1aff;
				border-color: #1a1aff;
				color: #fff !important;
			}
		}
	}

	&.active {
		padding-top: 60px;
		padding-bottom: 45px;
		background-color: $section-bg-black;

		h2,
		h3,
		h4,
		h5,
		p,
		span,
		li {
			color: #fff;
		}

		li {
			&::before {
				color: #fff !important;
			}
		}

		.pricing-head {
			border-color: rgba($color: #fff, $alpha: 0.1) !important;
		}
	}

	h2,
	h3,
	h5,
	h4 {
		color: $second-color;
	}

	.active-bage {
		position: absolute;
		right: 50px;
		top: 45px;
		padding: 8px 21px;
		border-radius: 30px;
		text-transform: capitalize;
		color: $white;
		background-color: $green;
		font-size: 16px;
		font-weight: 600;
	}

	.pricing-head {
		padding-bottom: 30px;
		border-bottom: 1px solid rgba($color: $second-color, $alpha: 0.1);

		.pricing-name {
			h4 {
				font-size: 20px;
			}
		}

		.pricing-vale {
			align-items: baseline;

			h2 {
				font-size: 72px;
				line-height: 115%;

				@media (max-width: 767px) {
					font-size: 50px;
				}
			}

			p {
			}
		}
	}

	.buy-package-btn {
		margin-top: 45px;

		.theme-btn {
			display: block;
			text-align: center;

			&.minimal-btn {
				background-color: transparent;
				color: $second-color;

				&:hover {
					background-color: $second-color;
					color: #fff;
					border-color: $second-color;
				}
			}
		}
	}
}

.pricing-arrow {
	position: absolute;
	bottom: 20px;
	right: 50px;

	@media (max-width: 1199px) {
		img {
			max-width: 60%;
		}
	}

	@media (max-width: 991px) {
		right: 0px;

		img {
			max-width: 50%;
		}
	}

	@media (max-width: 767px) {
		display: none;
	}
}
