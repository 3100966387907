/* --------------------------------------------
    Template Default Fonts & Fonts Styles
 ---------------------------------------------- */

// Font Family Variable with Value
// font-family: 'GeneralSans';
//font-family: 'GeneralSans';

$heading-font: 'GeneralSans', cursive;
$body-font: 'GeneralSans', sans-serif;

//font-family: "Font Awesome 5 Pro";
$fa: "Font Awesome 5 Pro";

body {
    font-family: $body-font;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: #617686;
    background-color: #fff;

    @media (max-width: 767px) {
        font-size: 18px;
    }
    
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font;
    margin: 0px;
    margin-bottom: 15px;
    font-weight: 600;    
    color: $heading-color;
    line-height: 125%;
}

h1 {
    font-size: 60px;
    line-height: 1.17;

    @media (max-width: 991px) {
        font-size: 42px;
    }
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
    line-height: 28px;

    @media (max-width: 767px) {
        font-size: 22px;
    }
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 18px;
}

a {
    text-decoration: none;
    outline: none !important;
    cursor: pointer;
    font-size: 16px;
    line-height: 28px;
    @include transition(.35s);
    color: $theme-color;
    font-weight: 500;
}

p {
    margin: 0px;

    b {
        color: $heading-color;
        font-weight: 500;
    }
}

.fs-lg {
    font-size: 80px;
    text-transform: uppercase;
    line-height: 1.14;

    @media (min-width: 767px) and (max-width: 991px) {
        font-size: 42px;
    }

    @media (max-width: 767px) {
        font-size: 36px;
    }
}


.fw500 {
    h1,h2,h3,h4,h5,h6 {
        font-weight: 500 !important;
    }
}