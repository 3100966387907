.cta-banner {
    background-color: $theme-color;
    padding-top: 80px;
    border-radius: 30px;
    overflow: hidden;

    &.style-2 {
        background-color: #F54748;
        position: relative;
        z-index: 1;
        padding-bottom: 80px;

        &::before {
            position: absolute;
            width: 300px;
            height: 300px;
            border-radius: 50%;
            background-color: #FFD44B;
            left: 0;
            bottom: 0;
            transform: translate(-50%, 50%);
            content: "";
            z-index: -1;
            animation: moverightbounce 3s infinite linear;
        }
    }

    &.newsletter-box {
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            width: 400px;
            height: 400px;
            border-radius: 50%;
            background-color: #FFD44B;
            left: 0;
            bottom: 0;
            transform: translate(-50%, 50%);
            content: "";
            z-index: -1;
            animation: moverightbounce 3s infinite linear;
        }

        .arrow-shape {
            position: absolute;
            right: -60px;
            bottom: 90px;

            @media (max-width: 767px) {
                display: none;
            }
        }


        .cta-contents { 

            @media (max-width: 991px) {
                padding-left: 50px;
                padding-right: 50px;
            }

            @media (max-width: 767px) {
                padding-left: 15px;
                padding-right: 15px;
            }

            h1 {
                @media (max-width: 485px) {
                    font-size: 30px;
                }
            }
        }
    }

    &.style-3 {
        .cta-mobile {
            position: relative;
            z-index: 1;
            margin-top: -80px;
            overflow: hidden;
            margin-bottom: -265px;

            @media (max-width: 1199px) {
                margin-top: 20px;
                margin-bottom: -170px;
            }

            @media (max-width: 767px) {
                margin-top: 0px;
                margin-bottom: -140px;
            }

            &::before {
                width: 603px;
                height: 603px;
                border-radius: 50%;
                background: #FFD44B;
                content: "";
                left: -5%;
                bottom: -5%;
                position: absolute;
                z-index: -1;

                @media (max-width: 1400px) {
                    width: 555px;
                    height: 555px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                @media (max-width: 1199px) {
                    width: 455px;
                    height: 455px;
                }

                @media (max-width: 600px) {
                    width: 320px;
                    height: 320px;
                }

            }

            img {
                @media (max-width: 1200px) {
                    max-width: 60%;
                }
            }
        }

        .cta-contents { 

            h1,h2,h3,h4 {
                font-weight: 500;
            }

            p {
                font-size: 20px;
            }
        }
    }

    .white-arrow-icon {
        position: absolute;
        right: -5.5%;
        top: 30px;
        z-index: -1;

        img {
            width: 80%;
        }
    }

    .cta-contents { 
        position: relative;


        @media (max-width: 1399px) {
            padding-left: 100px;
            padding-right: 100px;
        }

        @media (max-width: 767px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        h2 { 
            margin-top: -14px;
            font-size: 60px;
            line-height: 120%;

            @media (max-width: 1200px) {
                font-size: 45px;
            }

            @media (max-width: 767px) {
                font-size: 32px;
            }

            @media (max-width: 485px) {
                font-size: 28px;
            }
        }
    
        p {
            padding-right: 30px;
            margin-bottom: 10px;
            padding-top: 10px;

            @media (max-width: 767px) {
                padding-right: 0;
            }
        }

        a {
            margin-top: 40px;
            display: inline-block;
            margin-right: 25px;

            @media (max-width: 1400px) {
                margin-top: 30px;
                margin-left: 10px;
                margin-right: 10px;
            }

            @media (max-width: 767px) {
                margin-top: 20px;
            }

            @media (max-width: 500px) {
                display: block;
            }
        }

        .tri-arrow {
            display: inline-block;
            position: relative;
            right: -35px;
            bottom: 30px;


            @media (max-width: 1400px) {
                position: absolute;
                
                img {
                    max-width: 70%;
                }
            }
        }
    }

    .newsletter-form {
        padding-bottom: 80px;
        margin-top: 45px;

        form { 
            position: relative;
            width: 70%;
            margin: 0 auto;

            @media (max-width: 991px) {
                width: 90%;
            }

            @media (max-width: 485px) {
                width: 95%;
            }
    
            input { 
                width: 100%;
                height: 70px;
                padding: 0px 30px;
                background-color: #fff;
                border: 0px;
                border-radius: 3px;
                color: $heading-color;
                font-weight: 500;
                font-size: 18px;

                @media (max-width: 767px) {
                    height: 60px;
                    font-size: 16px;
                    padding-left: 15px;
                }

                &::placeholder {
                    color: rgba($color: $heading-color, $alpha: 0.4);
                }
            }
    
            .submit-btn { 
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                padding: 22px 28px;
                line-height: 1;
                text-align: center;
                background-color: $heading-color;
                color: #fff;
                border: 0;
                border-radius: 5px;
                font-weight: 600;
                font-size: 18px;
                transition: all .3s ease-in-out;

                @media (max-width: 767px) {
                    padding: 18px;
                    font-size: 14px;
                }

                @media (max-width: 485px) {
                    position: static;
                    display: block;
                    top: 0;
                    transform: translate(0);
                    width: 100%;
                    margin-top: 15px;
                    font-size: 16px;
                }

                &:hover {
                    background-color: $theme-color;
                    color: #fff;
                }
            }
        }
    }

    .cta-mobile-app {
        @media (max-width: 1400px) {
            text-align: center;
            margin-top: 50px;
        }

        @media (max-width: 767px) {
            margin-top: 20px;
        }
        
        img {
            position: relative;

            @media (min-width: 768px) {
                margin-left: -30px;
                margin-top: -50px;
            }

            @media (max-width: 767px) {
                max-width: 80%;
            }
        }
    }
}

.cta-banner-white-wrap {
    background-color: #fff;
    overflow: hidden;

    .cta-banner {
        overflow: inherit;
    }
}

.video-cta {
    position: relative;

    .video-play-btn {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(20px, -15px);

        @media (max-width: 767px) {
            right: 10px;
        }
        
        a {
            border: 10px solid #fff;
            color: $second-color;
            box-sizing: border-box;
            line-height: 80px !important;
        }
    }
}