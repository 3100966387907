@font-face {
    font-family: 'GeneralSans';
    src: url('GeneralSans-Regular.woff2') format('woff2'),
        url('GeneralSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GeneralSans';
    src: url('GeneralSans-Semibold.woff2') format('woff2'),
        url('GeneralSans-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GeneralSans';
    src: url('GeneralSans-Bold.woff2') format('woff2'),
        url('GeneralSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('GeneralSans-Medium.woff2') format('woff2'),
        url('GeneralSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
