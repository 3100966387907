.service-box-item { 
    background-color: #fff;
    box-shadow: 0px 4px 80px rgba(8, 15, 30, 0.06);
    border-radius: 10px;
    padding: 40px;
    margin-top: 30px;

    &:hover {
        .content { 
            > a {
                color: #F54748;
                
                i {
                    padding-left: 5px;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

	.icon { 
        margin-bottom: 25px;
	}

	.content { 

		h4 { 
            font-size: 20px;
		}

		p { 
            font-size: 16px;
            line-height: 24px;
		}

		a { 
            text-transform: capitalize;
            color: #617686;
            margin-top: 30px;
            display: block;
            font-weight: 600;
            font-size: 16px;

            i {
                color: #F54748;
                opacity: 0;
                visibility: hidden;
                padding-left: 0px;
                transition: .3s ease-in-out;
            }
		}
	}
}

.strong-service-card {
    position: relative;
    background: #4243DC;
    padding: 40px;
    padding-top: 50px;
    border-radius: 10px;
    transition: all .3s ease-in-out;
    z-index: 1;
    margin-top: 30px;

    &:hover {
        transform: rotate(-3deg);

        &::before {
           transform: rotate(3deg);
        }
    }

    &::before {
        transition: all .3s ease-in-out;
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        content: "";
        background: #4243DC;
        opacity: .10;
    }

    &.card2 {
        background: #EF2469;
    }

    &.card3 {
        background: #32C072;
    }

    &.card4 {
        background: #FFD44B;
    }

	.icon { 
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        position: relative;
        z-index: 1;
        color: #fff;
        font-size: 28px;
        margin-bottom: 140px;

        @media (max-width: 767px) {
            margin-bottom: 30px;
        }

        &::before {
            background: #fff;
            opacity: 0.2;
            width: 100%;
            height: 100%;
            z-index: -1;
            content: "";
            left: 0;
            top: 0;
            border-radius: 50%;
            position: absolute;
        }
	}

	.service-title { 

		h3 { 
            color: #fff;
            margin-bottom: 0;
            font-size: 24px;

            @media (max-width: 767px) {
                padding-right: 40px;
            }
		}
	}
}

.art-nice-icon {
    position: absolute;
    right: 100px;
    top: 200px;
}

.services-details-wrapper {
    img {
        margin-bottom: 20px;
        border-radius: 10px;
    }

    ul {
        list-style-type: disc !important;
        padding-left: 20px;

        li {
            margin-top: 20px;
            list-style-type: disc !important;
        }
    }
}

.work-process-wrapper {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-top: 50px;
    margin-bottom: 50px;

    @media (max-width: 1199px) {
       margin-top: 20px;
    }

    .single-work-process { 
        @media (max-width: 1199px) {
            margin-top: 30px;
        }
    }
    
    .wave-line {
        position: absolute;
        z-index: -1;
        top: 20px;
        width: 100%;
        left: 12%;
    }
}

.single-work-process { 
    position: relative;
    z-index: 1;

	.icon { 
        width: 70px;
        height: 70px;
        line-height: 70px;
        left: 364px;
        background: #F54748;
        color: #fff;
        text-align: center;
        border-radius: 50%;
        font-size: 30px;
        font-weight: 500;
        margin: 0 auto;
        margin-bottom: 30px;
	}

	.content { 

		h5 { 

		}

		p { 
            color: #617686;
		}
	}
} 