@font-face {
    font-family: 'xmoze';
    src: url('xmoze.woff2') format('woff2'),
        url('xmoze.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'xmoze';
    src: url('xmoze.woff2') format('woff2'),
        url('xmoze.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

