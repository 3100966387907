.preloader {
    align-items: center;
    cursor: default;
    display: flex;
    height: 100%;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9999999;

    .animation-preloader {
        z-index: 1000;

        .spinner {
            animation: spinner 1s infinite linear;
            border-radius: 50%;
            border: 3px solid rgba(0, 0, 0, 0.2);
            border-top-color: $theme-color;
            height: 9em;
            margin: 0 auto 3.5em auto;
            width: 9em;
        }

        .txt-loading {
            font: bold 5em $heading-font, sans-serif;
            text-align: center;
            user-select: none;

            @media (max-width: 767px) {
                font-size: 3.5em;
            }

            @media (max-width: 500px) {
                font-size: 2.5em;
            }

            .letters-loading {
                color: $second-color;
                position: relative;

                &:nth-child(2):before {
                    animation-delay: 0.2s;
                }

                &:nth-child(3):before {
                    animation-delay: 0.4s;
                }

                &:nth-child(4):before {
                    animation-delay: 0.6s;
                }

                &:nth-child(5):before {
                    animation-delay: 0.8s;
                }

                &:nth-child(6):before {
                    animation-delay: 1s;
                }

                &:nth-child(7):before {
                    animation-delay: 1.2s;
                }

                &:nth-child(8):before {
                    animation-delay: 1.4s;
                }

                &::before {
                    animation: letters-loading 4s infinite;
                    color: $second-color;
                    content: attr(data-text-preloader);
                    left: 0;
                    opacity: 0;
                    font-family: $heading-font;
                    position: absolute;
                    top: -3px;
                    transform: rotateY(-90deg);
                }
            }
        }
    }

    p {
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 8px;
        color: $theme-color;
    }

    .loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 0;
        z-index: 1;
        pointer-events: none;

        .row {
            height: 100%;
        }

        .loader-section {
            padding: 0px;

            .bg {
                background-color: #fff;
                height: 100%;
                left: 0;
                width: 100%;
                transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
            }
        }
    }

    &.loaded {
        .animation-preloader {
            opacity: 0;
            transition: 0.3s ease-out;
        }

        .loader-section {
            .bg {
                width: 0;
                transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
            }
        }
    }
}